<template>
<div>
    <div class="wrap">
        <form name="mfrm" @submit.prevent="getCompanyList" method="post">
            <div class="con_top">
                <h2><span>■</span> 기업목록</h2>
            </div>
            <div class="con">
                <div class="con_table">
                    <div class="float-left dp-block ml-20 mb-10">기간검색 :
                        <crm-datepicker v-model="startDate" :parentDate="startDate" format="yyyy-MM-dd" :language="$store.state.ko" /> ~
                        <crm-datepicker v-model="endDate" :parentDate="endDate" class="mr-10" format="yyyy-MM-dd" :language="$store.state.ko" />
                    </div>
                    <div class="search2">
                        <div class="float-left dp-block mb-10">
                            <input type="text" v-model="value" @keyup.enter="getCompanyList()" class="w-200px h-40px pd-00 pl-20" placeholder="기업명/담당자">
                            <a class="btn_search ml-10 pointer" @click="getCompanyList()">Search<span></span></a>
                        </div>
                        <div class="btns mb-30">
                            <a class="btn_new2 pointer" @click="excelDownload()">엑셀 다운로드</a>
                            <a class="btn_new2 pointer" @click="register()">기업등록</a>
                        </div>
                    </div>
                    <table class="default_table mt-20 break-all" >
                        <colgroup>
                            <col width="*" />
                            <col width="8%" />
                            <col width="6%" />
                            <col width="7%" />
                            <col width="7%" />
                            <col width="8%" />
                            <col width="5%" />
                            <col width="6%" />
                            <col width="7%" />
                            <col width="6%" />
                            <col width="6%" />
                            <col width="5%" />
                            <col width="5%" />
                            <col width="5%" />
                            <col width="5%" />
                            <col width="6%" />
                            <col width="*" />
                        </colgroup>
                        <thead v-if="loading">
                            <tr>
                                <th scope="col">No</th>
                                <th scope="col">기업명</th>
                                <th scope="col">담당자</th>
                                <th scope="col">담당연락처</th>
                                <th scope="col">담당 Email</th>
                                <th scope="col">계약기간</th>
                                <th scope="col">계약총액</th>
                                <th scope="col">MSS(금액)</th>
                                <th scope="col">프로그램(금액)</th>
                                <th scope="col">상담(총금액)</th>
                                <th scope="col">상담<br />총회기</th>
                                <th scope="col">상담<br />단가</th>
                                <th scope="col">지원<br />회기</th>
                                <th scope="col">검사<br />지원</th>
                                <th scope="col">가족<br />지원</th>
                                <th scope="col">유형</th>
                                <th scope="col">관리</th>
                            </tr>
                            <tr>
                                <td colspan="17">검색중입니다.</td>
                            </tr>
                        </thead>
                        <thead v-else>
                            <tr>
                                <th scope="col">No</th>
                                <th scope="col">기업명</th>
                                <th scope="col">담당자</th>
                                <th scope="col">담당<br />연락처</th>
                                <th scope="col">담당<br />Email</th>
                                <th scope="col">계약<br />기간</th>
                                <th scope="col">계약<br />총액</th>
                                <th scope="col">MSS<br />(금액)</th>
                                <th scope="col">프로그램<br />(금액)</th>
                                <th scope="col">상담<br />(총금액)</th>
                                <th scope="col">상담<br />총회기</th>
                                <th scope="col">상담<br />단가</th>
                                <th scope="col">지원<br />회기</th>
                                <th scope="col">검사<br />지원</th>
                                <th scope="col">가족<br />지원</th>
                                <th scope="col">특이<br />사항</th>
                                <th scope="col">관리</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) of companyList" :key="index">
                                <td>{{item.rownum}}</td>
                                <td @click="detailStatistic(item.idx)">
                                    <div class="underline pointer tooltip">
                                        {{item.name | textFormat}}
                                        <span class="tooltiptext">
                                            {{item.name}}
                                        </span>
                                    </div>
                                </td>
                                <td>{{item.manager}}</td>
                                <td>{{item.phone}}</td>
                                <td>{{item.managerEmail}}</td>
                                <!-- <td>{{item.createDate.slice(0,10)}}</td> -->
                                <td v-if="item.companyContract">
                                    {{ item.companyContract.consStartDate.slice(0,10) }} ~ {{ item.companyContract.consEndDate.slice(0,10) }}
                                </td>
                                <td v-if="item.companyContract">{{ numberWithCommas(item.companyContract.totalPrice) }}</td>
                                <td v-if="item.companyContract">{{ numberWithCommas(item.companyContract.mssPrice) }}</td>
                                <td v-if="item.companyContract">{{ numberWithCommas(item.companyContract.programPrice) }}</td>
                                <td v-if="item.companyContract">{{ numberWithCommas(item.companyContract.consPrice) }}</td>
                                <td v-if="item.companyContract">{{ numberWithCommas(item.companyContract.consTotalCount) }}</td>
                                <td v-if="item.companyContract">
                                    {{ numberWithCommas(  item.companyContract.interviewConsPrice +  item.companyContract.videoConsPrice +
                                    item.companyContract.phoneConsPrice) }}
                                </td>
                                <td v-if="item.companyContract">{{ numberWithCommas(item.companyContract.consCount) }}</td>
                                <td v-if="item.companyContract">{{item.companyContract.testYn}}</td>
                                <td v-if="item.companyContract">{{item.companyContract.familyYn}}</td>
                                <td v-else colspan="10">등록이 필요합니다.</td>
                                <td>
                                    <textarea ref="ref_etc" rows="5">{{item.etc}}</textarea>  
                                    <span class="underline pointer" @click="modifyEtc(index, item.idx, item.companyCode)">저장</span>
                                </td>
                                <td>
                                    <span class="underline pointer" @click="modify(item.idx)">
                                        수정
                                    </span>
                                </td>
                            </tr>
                            <tr v-if="companyList.length === 0">
                                <td colspan="8">검색 결과가 없습니다.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="con_table">
                    <div class="page">
                        <ul>
                            <li>
                                <a class="pointer" @click="prevPaging()"> &lt; </a>
                            </li>
                            <li v-for="index in calcMaxPaging()" :key="index" class="txt-blue">
                                <span class="pointer" style="padding: 8px;" @click="paging(index)">
                                    <a :class="{'bold': index === pageNum}">{{index}}</a>
                                </span>
                            </li>
                            <li>
                                <a class="pointer" @click="nextPaging()"> &gt; </a>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
</template>

<script>
import FileSaver from 'file-saver'
export default {
    data: () => ({
        startDate: '', // 기간검색-시작날짜
        endDate: '', // 기간검색-종료날짜
        companyList: [], // 기업 리스트
        value: '', // 검색 텍스트
        // 페이징에 필요한 변수들
        pageNum: 1,
        pageSize: 50,
        listLength: 0, // 리스트 size
        maxPagingCount: 1, // 최대 페이지 수
        maxPagePart: 1, // 최대 파트가 몇번째인지
        pagePart: 1, // 현재 페이징이 몇번째 파트에 있는지 (ex 1~10 1파트, 11~20 2파트)
        loading:true,

        ref_etc: [],
    }),

    filters:{
        textFormat(value){
            return value.length > 5 ? value.slice(0,5) +  '...' : value;
        }
    },

    mounted() {

        if(sessionStorage.getItem('origin_company_pageNum') !== 'undefined' && sessionStorage.getItem('origin_company_pageNum') !== null) {
            this.pageNum = Number(sessionStorage.getItem('origin_company_pageNum'))
        }
        if(sessionStorage.getItem('origin_company_value') !== 'undefined' && sessionStorage.getItem('origin_company_value') !== null) {
            this.value = sessionStorage.getItem('origin_company_value')
        }

        this.getCompanyList()
    },

    methods: {
        //기업 관리 엑셀 다운로드
        excelDownload(){
            let headers = {
                'Content-Type': 'ms-vnd/excel;charset=UTF-8'
            }

            let params = {
                value : this.value,
                startDate: this.startDate,
                endDate: this.endDate 
            }
            this.axios.defaults.headers.post[headers]
            this.axios.get('/api/v1/excel/company-list', {
                params: params,
                responseType: 'arraybuffer'
            }).then(res => {
                FileSaver.saveAs(new Blob([res.data]), "company_list_excel.xlsx")
            }).catch(err => {
                console.log(err)
                if (err.response.data.path == '/login/msg') {
                    alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                    this.$router.push('/login')
                } else {
                    alert('관리자에게 문의하세요')
                }
            })
        },


        // 이전 페이징
        prevPaging() {
            if (this.pageNum === 1) {
                return false
            } else {
                this.pageNum -= 1
                this.getCompanyList()
            }
        },
        paging(index) {
            this.pageNum = index
            this.getCompanyList()
        },

        // 현재 페이지의 페이지 범위 계산
        calcMaxPaging() {
            if (this.maxPagingCount > this.pagePart * 10) {
                this.maxPagePart = this.pagePart * 10
            } else {
                this.maxPagePart = this.maxPagingCount
            }
            var pagingArray = []
            for (var i = ((this.pagePart - 1) * 10) + 1; i <= this.maxPagePart; i++) {
                pagingArray.push(i)
            }
            return pagingArray
        },

        // 다음 페이징
        nextPaging() {
            if (this.pageNum === this.maxPagingCount) {
                return false
            } else {
                this.pageNum += 1
                this.getCompanyList()
            }
        },

        // 컴마 넣기
        numberWithCommas(x) {
            if(x === undefined) {
                return 0
            } else {
                x = x + ''
                return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
        },

        // 기업 목록 조회 api
        getCompanyList() {
            // this.axios.get('/company')
            //     .then(res => {
            //         
            //         if (res.data.err === 0) {
            //             this.companys = res.data.company
            //         } else {
            //             alert(res.data.result)
            //         }
            //     })
            //     .catch(err => {
            //         console.log(err)
            //         alert(err)
            //     })

            var params = {
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                value: this.value,
                startDate: this.startDate,
                endDate: this.endDate 
            }

            sessionStorage.setItem('origin_company_pageNum', this.pageNum)
            sessionStorage.setItem('origin_company_value', this.value)

            this.loading = true;

            this.axios.get('/api/v1/company/list', {
                params: params
            })
                .then(res => {
                    
                    if (res.data.err === 0) {
                        this.companyList = res.data.companyList

                        if (res.data.companySize) {
                            this.listLength = res.data.companySize
                        }
                        this.maxPagingCount = Math.ceil(this.listLength / this.pageSize)
                        this.maxPagePart = Math.ceil(this.maxPagingCount / 10)
                        this.pagePart = Math.ceil(this.pageNum / 10)
                    } else {
                        //alert(res.data.result)
                        this.companyList = []
                    }
                })
                .catch(err => {
                    console.log(err)
                })
                .finally(()=>{
                    this.loading = false;
                });
        },

        // 기업등록 페이지로 이동
        register() {
            this.$router.push('/origin/company_manage_register')
        },

        // 기업정보 상세보기 페이지로 이동
        detail(idx) {
            this.$router.push(`/origin/company_manage_info?idx=${idx}`)
        },

        /**
         * @description : 기업 통계 이동
         */
        detailStatistic(companyId){
            this.$router.push({
                name: "origin-statisticCompany",
                params: { companyId :companyId }
            });
        },
        // 기업수정 페이지로 이동
        modify(idx) {
            this.$router.push(`/origin/company_manage_modify?idx=${idx}`)
        },
        // 특이사항 수정
        modifyEtc(i, idx, companyCode) {
            
            let etc = this.$refs.ref_etc[i].value
            let vo = { 
                companyCode : companyCode,
                etc: etc 
            }

            this.axios.put(`/api/v1/company/${idx}`, vo)
                .then(res => {      
                    if (res.data.err === 0) {
                        alert(res.data.result)
                    } else {
                        alert(res.data.result)
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        }


    }
}
</script>
